<template>
<div>
  <headBack>
    <template v-slot:title>
      <div class='title'>
        录音信息收集协议
      </div>
    </template>
  </headBack>
  <div class='header_cont'></div>
  <div class="wrapper">
    <div class="title"> 《录音信息收集协议》</div>
    <div class="cont">为提升医护到家产品安全能力， 规避护患私下交易风险以及更好地处理医护到家平台的服务纠纷， 医护到家上线录音功能。本协议将向您说明平台收集使用录音信息的情况， 请您务必认真阅读本协议，在确认充分了解后慎重决定是否同意本协议。 您点击同意后，本协议生效，对您及医护到家平台均具有法律约束力。</div>
    <div class="list">一、个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，在本协议中包括但不限于：个人资料 （您的姓名、性别、年龄、出生日期、电话号码、身体状况、病情、住址或通讯地址、电子邮箱地址）、位置信息、通话记录、录音、订单信息及交易状态、支付信息、银行卡号等。</div>
    <div class="list">二、录音将通过医护到家护士端App进行。护士授权后，录音功能即可开启。如护士使用的医护到家护士App版本未及时更新，无法对录音进行授权，则录音不开启。</div>
    <div class="list">三、录音自医护到家护士开启护士端App录音功能时开始，至服务结束时止（具体以医护到家护士端App显示的录音状态为准）。医护到家其他上线录音功能的服务的录音起始时间以医护到家平台另行告知为准。</div>
    <div class="list">四、为保障用户的隐私，录音将实时上传至医护到家服务器，用户无法自行下载、调取或播放录音。</div>
    <div class="list">
      五、录音信息将用于以下明确列明的使用场景：<br />
      1.在征得用户及护士同意的情况下，作为平台处理用户纠纷的依据。<br />
      2.为维护用户人身安全等重大合法权益，或情况紧急又很难得到用户事先同意的。<br />
      3.用于抽查检验用户是否存在违反平台用户规则的行为。<br />
      4.用于系统分析，设计、开发、应用保护用户安全的辅助工具或产品。
    </div>
    <div class="list">六、医护到家平台将严格保护用户个人信息安全，除以下情况外，我们不会向其他人共享您的录音信息：<br />
      1.相关机关依据法定程序调取。<br />
      2.用户持法律文件依法调取。
    </div>
    <div></div>
    <div></div>
    <div>七、录音保存期限为60个自然日。如遇差评、投诉、尚未处理完毕的纠纷等，平台可适当延长录音保存期限。</div>
    <div>八、因手机等硬件设备故障、网络状态不稳定、App版本过旧以及不可抗力等因素均可能导致录音失败，用户对此表示理解，如遇此类问题，医护到家平台无需承担责任。</div>
    <div>九、医护到家平台将严格按照本协议约定收集使用用户录音信息。</div>


  </div>
</div>
</template>

<script>
export default {
  name: "luyinxieyi"
}
</script>

<style scoped lang="scss">
   .wrapper{
      box-sizing: border-box;
      padding:30px;
     font-size: 26px;
     color:#666;
     .title{
       color:#333;
       font-size: 28px;
       font-weight: bold;
     }
     div{
       line-height: 44px;
     }
   }
</style>
